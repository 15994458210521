<template>
  <div>
    <div id="nav" class="relative z-30">
      <div class="w-screen overflow-hidden relative z-10 px-8">
        <div class="container relative z-20 pt-8 relative flex justify-between items-center">
          <div class="relative mb-0">
            <slot name="brand"/>
          </div>
          <div class="h-8 gap-x-6">
            <a href="/contact-us" class="contat-header ">Contact us</a>
            <button name="main menu" aria-label="main menu" type="button" @click="addo();addy();addb();addc();"
                    class="text-center text-primary toggle-me font-lato text-sm uppercase tracking-widest	">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container z-10 heighter">
      <div class="pt-12 lg:py-12 flex justify-center">
        <div>
          <slot name="mainmenu"/>
        </div>
      </div>
      <div class="lg:flex pt-10 lg:pt-6 pb-6 px-8 justify-between items-center">
        <div class="lg:w-1/2">
          <p class="text-14 lg:text-base mb-8 lg:mb-0 text-white font-semibold leading-20">Poweroptix HQ, Suite V, Formation Works<br>
            Edenaveys Industrial Estate, Edenaveys Road, Armagh BT60 1NF
          </p>
        </div>
        <div class="lg:w-1/2">
          <div class="my-4 lg:my-0 flex gap-x-4 justify-start lg:justify-end items-center">
            <a href="" class="text-white hover:text-primary transition-all ease-in-out duration-300">
              <icon-fb></icon-fb>
            </a>
            <a href="" class="text-white hover:text-primary transition-all ease-in-out duration-300">
              <icon-twitter></icon-twitter>
            </a>
            <a href="" class="text-white hover:text-primary transition-all ease-in-out duration-300">
              <icon-linkedin></icon-linkedin>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      isOpenLeft: false,
      services: false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addo() {
      document.querySelector('.heighter').classList.toggle('heights')
    },
    addy() {
      document.querySelector('.content-pos').classList.toggle('toppos')
    },
    addb() {
      document.querySelector('.brand').classList.toggle('white-b')
    },
    addc() {
      document.querySelector('.contat-header').classList.toggle('white-c')
    },
  }
}
</script>
